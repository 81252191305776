import {Component, Input} from "@angular/core";
import {DisplayService} from "../display.service";
import {AccountService} from "../account/account.service";
import {NavigationService} from "../navigation.service";
import {SettingsService} from "../settings.service";
import {map} from "rxjs/operators";

@Component({
    selector: "app-global-navi",
    template: `
        <div *ngIf="!mobile" class="container">
            <nav class="row global-navi pl-3 pl-sm-0 pr-3 pr-sm-0">
                <ul class="nav-tabs">
                    <li class="nav-item" routerLinkActive="current"><a [routerLink]="basePaths.frontPage"><i class="fa fa-home" title="{{'main.links.main' | translate}}"></i><span class="sr-only">{{'main.links.main' | translate}}</span></a></li>
                    <li class="nav-item" routerLinkActive="current"><a [routerLink]="basePaths.search" translate>search.main.header</a></li>
                    <li class="nav-item" routerLinkActive="current"><a [routerLink]="basePaths.collections" translate>kirja.main.links.collection</a></li>
                    
                    <li class="nav-item" routerLinkActive="current"><a [routerLink]="basePaths.serialPublications" translate>serialpublications.main.header</a></li>
                    <li class="nav-item" routerLinkActive="current"><a [routerLink]="basePaths.papersForDay" translate>papers-for-day.main.header</a></li>
                    <li class="nav-item" routerLinkActive="current"><a [routerLink]="basePaths.clippings" translate>main.links.user.articles</a></li>
                    <li class="nav-item" routerLinkActive="current"><a [routerLink]="basePaths.tutkainPage" translate>tutkain.page.title</a></li>
                    <li *ngIf="personSearch$ | async"
                        class="nav-item fyeo" routerLinkActive="current"><a [routerLink]="basePaths.nameSearch" translate>main.name-search</a></li>
                    <li *ngIf="personSearch$ | async"
                        class="nav-item fyeo" routerLinkActive="current"><a [routerLink]="basePaths.personSearch" translate>main.ner-search</a></li>
                    <li *ngIf="accountService.loggedIn$ | async"
                        class="nav-item" routerLinkActive="current" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="accountPaths.account" rel="nofollow" translate>scrapbook.saved-content</a></li>
                </ul>

                <button *ngIf="!displayService.headerMinimized" class="btn btn-sm btn-kk-auto ms-auto align-self-center" (click)="displayService.headerMinimized = true">
                    <i class="fa fa-angle-double-up"><span class="sr-only">{{'nav.toggler.btn'|translate}}</span></i>
                </button>
            </nav>
        </div>

        <div *ngIf="mobile" class="p-4">
            <app-mobile-navi></app-mobile-navi>
        </div>
    `,
    styleUrls: [
        "./global-navi.scss"
    ]
})
export class GlobalNaviComponent {

    @Input() mobile: boolean;

    get basePaths() { return this.navigationService.basePaths; }

    get accountPaths() { return this.navigationService.accountPaths; }

    personSearch$ = this.accountService.userProperties$.pipe(map(a => a.canUsePersonSearch));

    constructor(public displayService: DisplayService,
                public accountService: AccountService,
                public navigationService: NavigationService,
                public settingsService: SettingsService) {
    }
}
