import {Injectable} from "@angular/core";
import {SettingsService} from "../settings.service";

@Injectable()
export class GiosgService {
    constructor(
        readonly settingsService: SettingsService
    ) {}

    public init(w: Window, t: Document, accountId: string) {
        const options = this.settingsService.commonOptions;
        if (options.includeExternalResources && options.giosgEnabled) {
            const scriptTag = 'script';
            const giosg = '_giosg';
            const host = 'https://service.giosg.com';
            const scriptElement: HTMLScriptElement = t.createElement(scriptTag);

            scriptElement.async = true;
            scriptElement.src = host + '/live2/' + accountId;

            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            w[giosg] = w[giosg] || function() {
                (w[giosg]._e = w[giosg]._e || []).push(arguments)
            };

            w[giosg]._c = accountId;
            w[giosg]._h = host;

            const n = t.getElementsByTagName(scriptTag)[0];
            n.parentNode.insertBefore(scriptElement, n);
        }
    }
}