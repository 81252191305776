import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { GiosgService } from "../connectors/giosg.service";
import { MatomoAnalyticsService } from "../analytics/matomo-analytics.service";

export enum ConsentCategories {
    ESSENTIAL = "ESSENTIAL",
    PREFERENCE = "PREFERENCE",
    STATISTIC = "STATISTIC",
    MARKETING = "MARKETING",
    OTHER = "OTHER"
}

const AGREED_CATEGORIES = "agreed-categories";
const DEFAULT_EXPIRY = 365;

/**
 * Manages the state of end-user cookie consents
 */
@Injectable()
export class UserconsentService {
    constructor(
        private cookieService: CookieService,
        private giosgService: GiosgService,
        private matomoService: MatomoAnalyticsService
    ) {}

    /**
     * Gets agreed categories from user's browser
     * @returns agreed categories from browser's cookies
     */
    public getAgreedCategories() { 
        return this.cookieService.get(AGREED_CATEGORIES);
    }

    /**
     * Sets agreed cookie consent categories to browser cookies vai cookieService
     * @param value agreen categories in string value separated by commas
     */
    public setAgreedCategories(value: string) { 
        this.cookieService.set(AGREED_CATEGORIES, value, DEFAULT_EXPIRY, "", "", true);
    }

    /**
     * Loads services that are within agreed consent categories
     */
    public loadServices() {
        const agreedCategories: string[] = this.getAgreedCategories().split(",");
        agreedCategories.forEach(category => {
            this.loadCategory(category, agreedCategories);
        });
    }

    private loadCategory(category: string, agreedCategories: string[]) {
        switch (category) {
            case ConsentCategories.PREFERENCE:
                this.giosgService.init(window, document, 'b624b636-40b7-11e9-8d7e-0242ac110012');
                break;
            case ConsentCategories.STATISTIC:
                this.matomoService.init();
                break;
        }
    }

    /**
     * Removes opted-out cookies from user's browser based on selected categories
     */
    public clearCategories() {
        const agreedCategories: string[] = this.getAgreedCategories().split(",");
        for (const category in ConsentCategories) {
            if(!agreedCategories.includes(category)) {
                this.clearCategory(category);
            }
        }
    }

    private clearCategory(category: string) {
        const currentCookies = this.cookieService.getAll();
        switch (category) {
            case ConsentCategories.PREFERENCE:
                this.cookieService.delete("csrftoken", "/", "5261.clients.giosgusercontent.com");
                this.cookieService.delete("gid", "/", "service.giosg.com");
                this.clearGiosg(currentCookies);
                break;
            case ConsentCategories.STATISTIC:
                Object.keys(currentCookies).forEach(cookie => {
                    if(cookie.includes("_ga") || cookie.includes("_pk")) {
                        this.cookieService.delete(cookie);
                    }
                });
                break;
            case ConsentCategories.OTHER:
        }
    }

    private clearGiosg(currentCookies: any) {
        Object.keys(currentCookies).forEach(cookie => {
            if(cookie.includes("giosg")) {
                this.cookieService.delete(cookie);
            }
        });
    }
}